import { Suspense } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { UserMenu } from 'components/userMenu/UserMenu';
import Login from 'components/Login/Login';
import Footer from 'components/Footer/Footer';
import { useSelectors } from 'hooks/UseSelectors';

import Loader from 'components/Loader/Loader';
import logo from '../../img/brand-logo.svg';
import css from './Navigation.module.scss';

export const Navigation = () => {
  const { isLoggedIn } = useSelectors();

  return (
    <>
      <header className={css.header}>
        <div className={css.wrapper}>
          <nav className={css.navigation}>
            <ul className={css.list}>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    `${isActive && css.activeLink} ${css.link} ${css.logo}`
                  }
                  to="/"
                >
                  <img
                    className={css['brand-img']}
                    src={logo}
                    alt="Завод решіткового настилу"
                    width={135}
                    height={48}
                  />
                </NavLink>
              </li>
              {isLoggedIn && (
                <>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive && css.activeLink} ${css.link}`
                      }
                      to="/latticeFlooring"
                    >
                      Решітковий настил
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive && css.activeLink} ${css.link}`
                      }
                      to="/piles"
                    >
                      Арматурні каркаси
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className={({ isActive }) =>
                        `${isActive && css.activeLink} ${css.link}`
                      }
                      to="/reinforcingGrid"
                    >
                      Арматурні сітки
                    </NavLink>
                  </li>
                </>
              )}
              {!isLoggedIn && (
                <>
                  <li className={css.container}>
                    <Login />
                  </li>
                </>
              )}
            </ul>
          </nav>
          {isLoggedIn && <UserMenu />}
        </div>
      </header>
      <main>
        <div className={css.container}>
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </div>
      </main>
      <Footer />
    </>
  );
};
