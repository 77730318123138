import { ProgressBar } from 'react-loader-spinner';

const Loader = () => (
  // <Grid
  //   height="45"
  //   width="45"
  //   color="rgb(12, 46, 181)"
  //   ariaLabel="grid-loading"
  //   radius="12.5"
  //   wrapperStyle={{
  //     display: 'flex',
  //     justifyContent: 'center',
  //     alignItems: 'center',
  //     // position: 'fixed',
  //     // top: '50%',
  //     // left: '50%',
  //     // transform: 'translate(-50%, -50%)',
  //   }}
  //   wrapperClass=""
  //   visible={true}
  // />
  <ProgressBar
    height="64"
    width="64"
    ariaLabel="progress-bar-loading"
    wrapperStyle={{
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    wrapperClass="progress-bar-wrapper"
    borderColor="var(--gray-blue)"
    barColor="var(--gray-blue)"
  />
);

export default Loader;
