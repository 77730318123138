import { createAsyncThunk } from '@reduxjs/toolkit';
import api from 'redux/api';

export const setLastUpdated = lastUpdated => {
  api.data.lastUpdated = lastUpdated;
};

export const getFetchData = async () => {
  try {
    const {
      data: { database, userstatus, lastUpdated },
    } = await api.fetchData();

    const arr = {
      lastUpdated: lastUpdated,
      userStatus: userstatus,
      dataBase: database,
    };

    return arr;
  } catch (error) {
    console.log(error);
  }
};

export const setData = createAsyncThunk(
  'base/setData',
  async (arr, thunkAPI) => {
    try {
      // console.log(await arr);
      api.setLastUpdated(await arr.lastUpdated);
      return arr;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);
