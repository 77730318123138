import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useAnimate, stagger } from 'framer-motion';
import { MenuToggle } from './MenuToggle';
import { logOut } from 'redux/auth/operations';
import { useSelectors } from 'hooks/UseSelectors';
import css from './UserMenu.module.scss';

export const UserMenu = () => {
  const dispatch = useDispatch();
  const handleLogout = () => dispatch(logOut());
  const { userName, userEmail } = useSelectors();
  const [isOpen, setIsOpen] = useState(false);

  const scope = useMenuAnimation(isOpen);

  return (
    <div ref={scope} className={css.wrapper}>
      <ul className={css.list}>
        <li>
          <p className={css.user}>
            Вітаємо, &nbsp;<span className={css.name}> {userName}</span>
          </p>
        </li>
        <li>
          <p className={css.email}>{userEmail}</p>
        </li>
        <li>
          <Link className={css.logout} onClick={handleLogout}>
            Logout
          </Link>
        </li>
      </ul>
      <MenuToggle toggle={() => setIsOpen(!isOpen)} />
    </div>
  );
};

function useMenuAnimation(isOpen) {
  const [scope, animate] = useAnimate();

  useEffect(() => {
    const menuAnimations = isOpen
      ? [
          [
            'ul',
            { transform: 'translateY(68px)' },
            { ease: [0.08, 0.65, 0.53, 0.96], duration: 0.32 },
          ],
          [
            'li',
            { transform: 'scale(1)', opacity: 1, filter: 'blur(0px)' },
            { delay: stagger(0.05), at: '-0.1' },
          ],
        ]
      : [
          [
            'li',
            { transform: 'scale(0.5)', opacity: 0, filter: 'blur(10px)' },
            { delay: stagger(0.05, { from: 'last' }), at: '<' },
          ],
          ['ul', { transform: 'translateY(-100%)' }, { at: '-0.1' }],
        ];

    animate([
      [
        'path.top',
        { d: isOpen ? 'M 3 16.5 L 17 2.5' : 'M 2 2.5 L 20 2.5' },
        { at: '<' },
      ],
      ['path.middle', { opacity: isOpen ? 0 : 1 }, { at: '<' }],
      [
        'path.bottom',
        { d: isOpen ? 'M 3 2.5 L 17 16.346' : 'M 2 16.346 L 20 16.346' },
        { at: '<' },
      ],
      ...menuAnimations,
    ]);
  }, [animate, isOpen]);

  return scope;
}
