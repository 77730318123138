import { createAsyncThunk } from '@reduxjs/toolkit';
import jwt_decode from 'jwt-decode';
import api from 'redux/api';

// api.token = {
//   set(token) {
//     api.data.token = token;
//   },
//   unset() {
//     api.data.token = '';
//   },
// };

export const signIn = createAsyncThunk(
  'auth/signin',
  async (googleToken, thunkAPI) => {
    try {
      const userInfo = jwt_decode(googleToken);
      api.setToken(googleToken);
      // token.set(googleToken);

      const arr = {
        user: {
          name: userInfo.name,
          email: userInfo.email,
        },
        token: googleToken,
      };
      console.log('arr', arr);
      return arr;
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);

export const logOut = createAsyncThunk('auth/logout', async (_, thunkAPI) => {
  try {
    api.unsetToken();
    api.unsetLastUpdated();
  } catch (error) {
    thunkAPI.rejectWithValue(error);
  }
});

export const getUser = createAsyncThunk('auth/getuser', async (_, thunkApi) => {
  const state = thunkApi.getState();
  const persistedToken = state.auth.token;
  const persistedUserStatus = state.base.userStatus;
  const persistedDataBase = state.base.dataBase;
  const persistedLastUpdated = state.base.lastUpdated;

  if (persistedToken === null) {
    return thunkApi.rejectWithValue();
  }
  api.setToken(persistedToken);
  try {
    const { name, email } = jwt_decode(persistedToken);

    const arr = {
      user: {
        name: name,
        email: email,
      },
      userStatus: persistedUserStatus,
      dataBase: persistedDataBase,
      token: persistedToken,
      lastUpdated: persistedLastUpdated,
    };
    // console.log('arr: ', arr);
    return arr;
  } catch (error) {
    return thunkApi.rejectWithValue(error);
  }
});
