const api = {
  data: {},
  json: {},
  scriptURL:
    'https://script.google.com/macros/s/AKfycbzYm2s7L8-N8kPoZm2fCozr1boc8LDUoZo8XJwiXk63G0-Ax4ZUjhtlx5fMmoOMCE87/exec',
  // 'https://script.google.com/macros/s/AKfycbw6N94sxAZGyhmi00zqPIIofeZyuY8mEZI6iVWKQkpk9T9T2pphr0VPZKt96_3thZWL/exec',

  setToken(token) {
    this.data.token = token;
  },

  unsetToken() {
    this.data.token = '';
  },

  setLastUpdated(lastUpdated) {
    this.data.lastUpdated = lastUpdated;
  },

  unsetLastUpdated() {
    this.data.lastUpdated = '';
  },

  fetchData: async function () {
    try {
      const response = await fetch(this.scriptURL, {
        method: 'POST',
        body: JSON.stringify(this.data),
      });
      if (response !== '') this.json = await response.json();
      // console.log('Success:', this.json);

      return this.json;
    } catch (e) {
      console.log('Errors:', e.message);
    }
  },
};

export default api;
