import React from 'react';
import { useDispatch } from 'react-redux';

// import { useState, useEffect } from 'react';

import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';

// import { useSelectors } from 'hooks/UseSelectors';
// import css from './pages.module.scss';

import { signIn } from 'redux/auth/operations';

const Login = () => {
  const dispatch = useDispatch();

  const addGoogleToken = googleToken => {
    signIn(googleToken);
    dispatch(signIn(googleToken));
  };
  return (
    // <div className={css['form-container']}>
    // <h1 className={css['form-title']}>Вхід</h1>
    // <div className={css.content}>
    <GoogleOAuthProvider clientId="284068892475-q2fqn6amam78ru3lkvo7d4le015af61f.apps.googleusercontent.com">
      <GoogleLogin
        onSuccess={credentialResponse => {
          addGoogleToken(credentialResponse.credential);
        }}
        onError={() => {
          console.log('Login Failed');
        }}
        text="signin_with"
        shape="rectangular"
        size="large"
        width="200"
      />
    </GoogleOAuthProvider>
    // </div>
    // </div>
  );
};

export default Login;
