const USER_STATUS = {
  NO_TOKEN: 'no-token',
  TOKEN_NO_FINDED: 'token-no-finded',

  NEW: 'new',
  NO_VERIFID: 'no-verifid',
  VERIFID: 'verifid',
};

export default USER_STATUS;
