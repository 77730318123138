import { createSlice } from '@reduxjs/toolkit';
import { setData, setLastUpdated } from './operations';
import { logOut, getUser } from '../auth/operations';

export const baseSlice = createSlice({
  name: 'base',
  initialState: {
    userStatus: null,
    dataBase: null,
    lastUpdated: null,
    error: null,
  },

  extraReducers: builder => {
    builder
      .addCase(setData.fulfilled, (state, action) => {
        if (action.payload && 'dataBase' in action.payload) {
          state.dataBase = action.payload.dataBase;
          state.lastUpdated = action.payload.lastUpdated;
          state.userStatus = action.payload.userStatus;
          state.error = null;
        } else {
          console.error(
            'Received invalid payload in setData.fulfilled:',
            action.payload
          );
        }
      })
      .addCase(logOut.fulfilled, state => {
        state.userStatus = null;
        state.lastUpdated = null;
        state.dataBase = null;
      })
      .addCase(setData.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.userStatus = action.payload.userStatus;
        state.dataBase = action.payload.dataBase;

        state.lastUpdated = action.payload.lastUpdated;
        setLastUpdated(action.payload.lastUpdated);
      });
  },
});
