import { useEffect, useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useSelectors } from 'hooks/UseSelectors';
import { setData, getFetchData } from 'redux/base/operations';
import USER_STATUS from 'redux/auth/userStatus';
import { logOut } from 'redux/auth/operations';

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const BaseUpdate = () => {
  const shortUpdateInterval = 100;
  const longUpdateInterval = 30000;

  const [updateInterval, setUpdateInterval] = useState(shortUpdateInterval);
  const [update, setUpdate] = useState(false);

  const dispatch = useDispatch();
  const { isLoggedIn, dataBase, lastUpdated } = useSelectors();

  useEffect(() => {
    //First download
    if (update) {
      return;
    }
    if (isLoggedIn && !dataBase) {
      // console.log('FULL Update!!!');
      // console.time('test1');
      setUpdate(true);

      dispatch(setData(getFetchData())).then(() => {
        // console.timeEnd('test1');
        setUpdate(false);
      });
    }
  }, [isLoggedIn, dataBase, dispatch, update]);

  useInterval(() => {
    if (isLoggedIn) {
      if (!update) {
        // const timeTest = Date.now();

        setUpdate(true);
        const arr = (async () =>
          await getFetchData().then(data => {
            // console.log(data.userStatus);
            if (USER_STATUS.VERIFID !== data.userStatus) {
              dispatch(logOut());
              return console.log('userStatus:', data.userStatus);
            }

            const oldUpdatedDate = new Date(lastUpdated).getTime();
            const newUpdatedDate = new Date(data.lastUpdated).getTime();

            if (newUpdatedDate > oldUpdatedDate) {
              console.log('Update!!!');
              dispatch(setData(arr));
            }
            // console.log('TestTimeResponse: ', Date.now() - timeTest);
          }))();

        setUpdate(false);
      }
    }

    if (updateInterval === shortUpdateInterval) {
      setUpdateInterval(longUpdateInterval);
    }
  }, updateInterval);

  return null;
};

export default BaseUpdate;
