import { createSlice } from '@reduxjs/toolkit';

export const Filter = createSlice({
  name: 'filter',
  initialState: {
    filter: {
      startCalendarPeriod: new Date().setHours(0, 0, 0, 0),
      endCalendarPeriod: new Date().setHours(0, 0, 0, 0),
    },
  },
  reducers: {
    setFilter: (state, { payload }) => {
      state.filter = payload;
    },
  },
});

export const { setFilter } = Filter.actions;
