import { lazy, useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { AnimatePresence } from 'framer-motion';

import { RestrictedRoute } from '../RestrictedRoute';
import { Navigation } from '../navigation/Navigation';
import { getUser, logOut } from 'redux/auth/operations';
import { useSelectors } from 'hooks/UseSelectors';
import Loader from '../Loader/Loader';
import BaseUpdate from 'components/BaseUpdate/BaseUpdate';

import '../CSSEffect/CSSEffect.scss';
import css from './App.module.scss';

const Home = lazy(() => import('pages/Home'));
const LatticeFlooring = lazy(() => import('pages/LatticeFlooring'));
const Piles = lazy(() => import('pages/Piles'));
const ReinforcingGrid = lazy(() => import('pages/ReinforcingGrid'));

const App = () => {
  const [newUser, setNewUser] = useState(false);
  const dispatch = useDispatch();
  const { isRefreshing, isLoggedIn, userStatus } = useSelectors();

  useEffect(() => {
    dispatch(getUser());
  }, [dispatch]);

  useEffect(() => {
    if (userStatus === 'no-verifid' && isLoggedIn) dispatch(logOut());
  }, [userStatus, isLoggedIn, dispatch]);

  useEffect(() => {
    if (userStatus === 'new' && isLoggedIn) {
      setNewUser(true);
    }
  }, [userStatus, isLoggedIn, dispatch]);

  function LocationProvider({ children }) {
    return <AnimatePresence>{children}</AnimatePresence>;
  }

  function RoutesWithAnimation() {
    const location = useLocation();

    return (
      <Routes location={location} key={location.key}>
        <Route path="/" element={<Navigation />}>
          <Route index element={<Home />} />

          <Route
            path="latticeFlooring"
            element={
              <RestrictedRoute>
                {newUser && (
                  <p className={css.demo}>
                    Демо-режим. Для отримання повного доступу до ресурсу
                    очікуйте підтвердження Вашого e-mail.
                  </p>
                )}
                <LatticeFlooring />
              </RestrictedRoute>
            }
          />
          <Route
            path="piles"
            element={
              <RestrictedRoute>
                {newUser && (
                  <p className={css.demo}>
                    Демо-режим. Для отримання повного доступу до ресурсу
                    очікуйте підтвердження Вашого e-mail.
                  </p>
                )}
                <Piles />
              </RestrictedRoute>
            }
          />
          <Route
            path="reinforcingGrid"
            element={
              <RestrictedRoute>
                {newUser && (
                  <p className={css.demo}>
                    Демо-режим. Для отримання повного доступу до ресурсу
                    очікуйте підтвердження Вашого e-mail.
                  </p>
                )}
                <ReinforcingGrid />
              </RestrictedRoute>
            }
          />
        </Route>
      </Routes>
    );
  }

  return (
    <>
      {isRefreshing ? (
        <>
          <h2>Loading Phonebook...</h2>
          <Loader />
        </>
      ) : (
        <>
          <LocationProvider>
            <RoutesWithAnimation />
          </LocationProvider>
          <BaseUpdate />
        </>
      )}
    </>
  );
};

export default App;

// https://demo.themesberg.com/neumorphism-ui-pro/
