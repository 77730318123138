import { configureStore } from '@reduxjs/toolkit';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import indexedDBStorage from 'redux-persist-indexeddb-storage';

import { authSlice } from './auth/slice';
import { baseSlice } from './base/slice';
import { contactsSlice } from './contacts/slice';
import { Filter } from './filter/slice';

const commonPersistConfig = {
  storage: indexedDBStorage('localDB'),
  whitelist: ['token', 'dataBase', 'userStatus', 'lastUpdated'],
};

const authPersistConfig = {
  key: 'auth',
  ...commonPersistConfig,
};

const dataPersistConfig = {
  key: 'data',
  ...commonPersistConfig,
};

const rootReducer = {
  base: persistReducer(dataPersistConfig, baseSlice.reducer),
  auth: persistReducer(authPersistConfig, authSlice.reducer),
  contacts: contactsSlice.reducer,
  filter: Filter.reducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// import { configureStore } from '@reduxjs/toolkit';
// import storage from 'redux-persist/lib/storage';
// import {
//   persistReducer,
//   persistStore,
//   FLUSH,
//   REHYDRATE,
//   PAUSE,
//   PERSIST,
//   PURGE,
//   REGISTER,
// } from 'redux-persist';

// import { authSlice } from './auth/slice';
// import { baseSlice } from './base/slice';
// import { contactsSlice } from './contacts/slice';
// import { Filter } from './filter/slice';

// const authPersistConfig = {
//   key: 'auth',
//   storage,
//   // whitelist: ['token', 'dataBase', 'status'],
// };

// const dataPersistConfig = {
//   key: 'data',
//   storage,
//   // whitelist: ['token', 'dataBase', 'status'],
// };

// export const store = configureStore({
//   reducer: {
//     base: persistReducer(dataPersistConfig, baseSlice.reducer),
//     auth: persistReducer(authPersistConfig, authSlice.reducer),
//     contacts: contactsSlice.reducer,
//     filter: Filter.reducer,
//   },

//   middleware: getDefaultMiddleware =>
//     getDefaultMiddleware({
//       serializableCheck: {
//         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
//       },
//     }),
// });

// export const persistor = persistStore(store);
