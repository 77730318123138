import React from 'react';
import { Link } from 'react-router-dom';
import css from './Footer.module.scss';

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={css.footer}>
      <div className={css.container}>
        <div className={css.row}>
          <div className={css.column}>
            <h4>Про нас</h4>
            <a className={css.main_site} href="https://zrn.com.ua">
              Сайт компанії
            </a>
          </div>
          <div className={css.column}>
            <h4>Навігація</h4>
            <ul>
              <li>
                <Link to="/">Головна</Link>
              </li>
              <li>
                <Link to="/latticeFlooring">Решітковий настил</Link>
              </li>
              <li>
                <Link to="/piles">Арматурні каркаси</Link>
              </li>
              <li>
                <Link to="/reinforcingGrid">Арматурні сітки</Link>
              </li>
            </ul>
          </div>
          <div className={css.column}>
            <h4>Контакти</h4>
            <a href="mailto:ia@zrn.com.ua" className={css.main_site}>
              E-mail: ia@zrn.com.ua
            </a>
          </div>
        </div>
        <div className={css.copyright}>
          <p>
            &copy; {currentYear} Система контролю якості. Усі права захищені.
          </p>
          <p className={css.dev}>
            Розробка та дизайн:
            <a href="mailto:m.a.startsev@gmail.com" className={css.main_site}>
              Startsev M.
            </a>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
